import { Button, Grid, MenuItem, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { useTranslation } from '@contexts/translation'
import { onCreateList } from '@api/lists'
import { useEffect, useState } from 'react'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'

import Select from '@components/common/Select'
import { responseHandler } from '@utils/responseHandler'

export default function AddListForm({
  operationId,
  fetchListsURL,
  handleClose
}) {
  const { enqueueSnackbar } = useSnackbar()
  const { dictionary } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const schema = yup.object().shape({
    operation: !operationId
      ? yup.string().required(dictionary.required)
      : yup.string(),
    title: yup.string().required(dictionary.required)
  })

  const { data: operations, isLoading: loadingOperations } = useFetchData(
    !operationId ? API_URL.PROJECTS.GET_PROJECT_LIST_PK : null
  )

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (operationId) {
      setValue('operation', operationId.toString())
    }
  }, [operationId])

  const onSubmit = async values => {
    setIsLoading(true)

    const payload = {
      operation: operationId ? operationId : values.operation,
      title: values.title,
      is_private: true
    }

    const response = await onCreateList(payload)
    responseHandler({
      response,
      callback: async () => {
        await mutate(fetchListsURL)
      },
      dictionary,
      msgSuccess: dictionary.list_created,
      snackbar: enqueueSnackbar
    })

    setIsLoading(false)
    handleClose()
  }

  return (
    <Grid item xs={12}>
      <form data-cy={'form'} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {!operationId && (
            <Grid item xs={12}>
              <Select
                fullWidth
                required={!operationId}
                name="operation"
                label={dictionary.project}
                variant="outlined"
                control={control}
                defaultValue={operationId ? operationId.toString() : ''}
                error={Boolean(errors.operation)}
                errorMessage={errors.operation && errors.operation.message}
                isLoading={loadingOperations}
                data_testid="Select-a632f888-bccf-4bc9-89ab-5523a21416a3"
              >
                {operations &&
                  operations.results.map(({ id, name }) => (
                    <MenuItem
                      key={id}
                      value={id}
                      data-testid="MenuItem-c5718c15-88ed-45f6-9d48-b1f192b606f5"
                    >
                      {name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          )}

          <Grid item xs={12}>
            <Controller
              control={control}
              name="title"
              defaultValue={''}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    variant="filled"
                    label={dictionary.title}
                    error={Boolean(errors.title)}
                    helperText={errors?.title?.message}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              data-testid="Button-ac74f994-64b3-40b7-90b0-d37c6ca5a029"
            >
              {dictionary.save}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}
