import client from '@config/client'
import API_URL from '@config/services'
import queryString from '@utils/queryString'

export const onCreateTask = payload =>
  client
    .post(API_URL.TASKS.POST_TASKS_LIST(), payload)
    .then(response => response)
    .catch(error => {
      return error.response
    })

export const onFetchTasks = (params = {}) =>
  client
    .get(
      Object.keys(params).length > 0
        ? API_URL.TASKS.GET_TASK_LIST() + '?' + queryString(params)
        : API_URL.TASKS.GET_TASK_LIST()
    )
    .then(response => response)
    .catch(error => {
      return error.response
    })

export const onEditTask = (taskId, payload = {}) =>
  client
    .patch(API_URL.TASKS.PATCH_TASKS(taskId), payload)
    .then(response => response)
    .catch(error => error.response)

export const onDeleteTask = taskId => {
  return client
    .delete(API_URL.TASKS.DELETE_TASKS(taskId))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export const onLogTasks = orgId =>
  client
    .post(API_URL.TASKS.POST_TASKS_LOG_TAKS(orgId))
    .then(response => response)
    .catch(error => error.response)

export const onFetchLists = id => {
  return client
    .get(API_URL.TASKS.GET_TASKS_LISTS_LIST(id))
    .then(response => response)
    .catch(error => {
      return error.response
    })
}
