import client from '@config/client'
import API_URL from '@config/services'

export const onCreateList = (payload = {}) =>
  client
    .post(API_URL.TASKS.GET_TASKS_LISTS_LIST(), payload)
    .then(response => response)
    .catch(error => error.response)

export const onEditList = (listId, payload = {}) =>
  client
    .patch(`${API_URL.TASKS.GET_TASKS_LISTS_LIST()}${listId}`, payload)
    .then(response => response)
    .catch(error => error.response)

export const onDeleteList = listId =>
  client
    .delete(`/tasks/lists/${listId}/`)
    .then(response => response)
    .catch(error => error.response)
